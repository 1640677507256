<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
    >
      <h1 class="titulo" v-translate>CRM</h1>
      <div class="box-table">
        <v-skeleton-loader
          :loading="loadingSkeleton"
          :transition="'fade-transition'"
          height="500"
          type="table">
          <v-data-table
            :headers="headers"
            :items="listaItens"
            width="300px"
            height="auto"
            flat
            dense
            hide-default-footer
            :expanded.sync="expanded"
            show-expand
            :item-key="'entity_id'"
            :items-per-page.sync="itemsPerPage"
            :loading="loadingTable"
            @click:row="opemModalMemo"
            @item-expanded="handlerGetMemosByEntity"
          >
            <template v-slot:top>
              <BrToolBar @search="buscar" 
                :configFilter="{ 
                  disableTextField: true 
                }" 
                :addlFilter="true">
                <template v-slot:btnSelect>
                  <v-row class="justify-end align-center">
                    <v-checkbox
                      v-model="isBarra"
                      :label="'Barra'"
                      dense
                      hide-details
                    />

                    <v-col cols="12" md="3">
                      <v-text-field
                        append-icon="search"
                        :label="'Telefone'"
                        single-line
                        hide-details
                        v-on:keyup.enter="searchByPhone(search)"
                        v-model="search"
                        solo
                        dense
                        v-mask="'(##) #####-####'"
                        color="primary"
                        class="mb-0" />
                    </v-col>

                    <v-checkbox
                      v-model="typeStatus"
                      :label="'Não'"
                      dense
                      hide-details
                    />

                    <v-col cols="12" md="3">
                      <v-select
                        v-model="status"
                        :items="itemsStatus"
                        label="Status"
                        dense
                        hide-details
                        solo
                        clearable
                        class="select-status"
                      >
                      </v-select>
                    </v-col>

                    <v-col cols="12" md="3">
                      <FiltroRangeDate
                        class="mr-3 range-date"
                        @input="filtrar"
                        :value="rangeDate"
                        :itemsTypesFilters="arrTiposFiltros"
                        :labelBtn="'Selecionar Data'"
                        :actionLabels="{ apply: 'OK' }"
                        style="width: 100%;"
                        :disableBtnClear="false"

                      />
                    </v-col>
                  </v-row>
                </template>
              </BrToolBar>
            </template>

            <template v-slot:item.cod="{ item }">
              {{ item.cod | phone2 }}
            </template>

            <template v-slot:item.cred_first="{ item }">
              {{ item.cred_first | datas }}
            </template>

            <template v-slot:item.cred_last="{ item }">
              {{ item.cred_last | datas }}
            </template>

            <!-- <template v-slot:item.credito="{ item }">
              {{ item.credito | currency }}
            </template> -->

            <template v-slot:item.credito_ativado="{ item }">
              {{ item.credito_ativado | currency }}
            </template>

            <template v-slot:item.credito_gasto="{ item }">
              {{ item.credito_gasto | currency }}
            </template>

            <template v-slot:item.dt_last_game="{ item }">
              {{ item.dt_last_game | datas }}
            </template>

            <template v-slot:item.dt_validated_phone="{ item }">
              {{ item.dt_validated_phone | datas }}
            </template>
            
            <template v-slot:item.lastlogin="{ item }">
              {{ item.lastlogin | datas }}
            </template>

            <template v-slot:item.validated_phone="{ item }">
              <v-btn
                small
                outlined 
                :color="variables.colorError"
                rounded
                shaped
                class="mr-2"
                :class="{ 'telefone-validado': isPhoneValided(item) }"
                @click.stop="handlerChangeStatus(item, 'telefone')"
                :loading="loadingBtns"
              >
                {{ item.validated_phone === 'Sim' ? 'Validado' : 'Não Validado' }}
              </v-btn>
            </template>

            <template v-slot:item.whatsappactive="{ item }">
              <v-btn
                small
                outlined 
                :color="variables.colorError"
                rounded
                shaped
                class="mr-2"
                :class="{ 'whatsapp-ativado': isWhatsappActivated(item) }"
                @click.stop="handlerChangeStatus(item, 'whatsapp')"
                :loading="loadingBtns"
              >
                {{ item.whatsappactive === 'Sim' ? 'Sim' : 'Não' }}
              </v-btn>
            </template>

            <template v-slot:item.acceptedreseller="{ item }">
              <v-btn
                small
                outlined 
                :color="variables.colorError"
                rounded
                shaped
                class="mr-2"
                :class="{ 'ser-revendedor': isAcceptedReseller(item) }"
                @click.stop="handlerChangeStatus(item, 'revendedor')"
                :loading="loadingBtns"
              >
                {{ item.acceptedreseller === 'Sim' ? 'Sim' : 'Não' }}
              </v-btn>
            </template>

            <template v-slot:footer>
              <div class="wrapper-footer">
                <div class="qtd-paginacao">
                  {{ pageStart }}
                  -
                  {{ pageStop }}
                  de
                  {{ totalItens }}
                </div>
                <v-pagination
                  v-model="page"
                  :length="numberOfPages"
                  circle
                  :disabled="disabled"
                  :total-visible="7"
                  class="box-table-paginacao"
                  v-if="numberOfPages > 1"></v-pagination>
              </div>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="table-expend ">
                 <v-col class="d-flex justify-center align-center" style="min-height: 150px; width: 100%;" v-if="loadingExpanded">
                  <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </v-col>

                <v-data-table
                  v-else
                  class="mt-4"
                  :headers="headerMemos"
                  :items="listMemos"
                  width="300px"
                  flat
                  hide-default-footer
                  :items-per-page.sync="listMemos.length"
                >
                  <template v-slot:item.created="{ item }">
                    {{ item.created | datas }}
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </v-container>

    <ModalMemo 
      :activeModal="showModal"
      :entity="entitySelected"
      @closeModal="closeModal"
      @refreshCRM="refreshCRM"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import { validationMixin } from 'vuelidate'
import { required  } from 'vuelidate/lib/validators'
// import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'
import moment from 'moment'
import listagemTables from '@/views/mixins/listagemTables'
import { errorSnackbar, successSnackbar, currency } from '@/core/service/utils'


/**
 * MIXIN
 */
// import listagemTables from '@/views/mixins/listagemTables'

moment.locale('pt-BR')

export default {
  name: 'EsportesBilhetes',
  components: {
    // autoCompleteEntities: () => import('@/views/components/autoCompleteEntities')
    FiltroRangeDate: () => import('@/views/components/filtroRangeDate'),
    BrToolBar: () => import('./components/toolbar'),
    ModalMemo: () => import('./components/modalMemo')
  },
  mixins: [validationMixin, listagemTables],
  directives: {mask},
  data: () => ({
    loadingSkeleton: false,
    loadingTable: false,
    loadingBtns: false,
    page: 1,
    showModal: false,
    bilhetePremiado: {},
    status: '',
    entitySelected: {},
    headers: [
      { sortable: true, class: 'table-header', text: 'Nome', value: 'name' },
      { sortable: true, class: 'table-header', text: 'Telefone', value: 'cod' },
      { sortable: true, class: 'table-header', text: 'Data de 1º Credito', value: 'cred_first' },
      { sortable: true, class: 'table-header', text: 'Data de Ultimo Credito', value: 'cred_last' },
      { sortable: true, class: 'table-header', text: 'Qtd. creditos', value: 'cred_qtde' },
      // { sortable: true, class: 'table-header', text: 'Total creditos', value: 'credito' },
      { sortable: true, class: 'table-header', text: 'Créditos ativados', value: 'credito_ativado' },
      { sortable: true, class: 'table-header', text: 'Créditos gastos', value: 'credito_gasto' },
      { sortable: true, class: 'table-header', text: 'Cadastro', value: 'web_created' },
      { sortable: true, class: 'table-header', text: 'Data ultimo jogo', value: 'dt_last_game' },
      { sortable: true, class: 'table-header', text: 'Celular Validado', value: 'validated_phone' },
      { sortable: true, class: 'table-header', text: 'Whatsapp', value: 'whatsappactive' },
      { sortable: true, class: 'table-header', text: 'Revender', value: 'acceptedreseller' },
      { sortable: true, class: 'table-header', text: 'Data celular validado', value: 'dt_validated_phone' },
      { sortable: true, class: 'table-header', text: 'Último Login', value: 'lastlogin' },
      // { align: 'end', sortable: false, class: 'table-header', text: '', value: 'actions' }
    ],

     headerMemos: [
      // { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      { align: 'start', class: 'table-header', text: 'Anotação', value: 'memo' },
      { align: 'start', class: 'table-header', text: 'Data', value: 'created' },
    ],
    rangeDate: {
      start: moment().format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD')
    },
    customTokens: {
      mask: '(##) #####-####',
    },

    arrTiposFiltros: [
      { text: 'Data do 1º crédito', value: 'cred_first' },
      { text: 'Data do último crédito', value: 'cred_last' },
      { text: 'Data do último jogo', value: 'dt_last_game' },
      { text: 'Data do celular validado', value: 'dt_validated_phone' },
      { text: 'Data do último acesso', value: 'lastlogin' }
    ],

    typeDate: 'lastlogin',
    typeStatus: true,
    expanded: [],
    loadingExpanded: false,
    isBarra: false
  }),

  mounted () {
    this.loadingSkeleton = true
    this.getData({
        page: 1,
        status: this.status || undefined,
        size: this.$store.getters.ItensPaginacao,
        dtStart: this.rangeDate.start,
        dtEnd: this.rangeDate.end,
        typeDate: this.typeDate,
        typeStatus: this.status ? this.typeStatus ? 'Não' : 'Sim'  : undefined,
        isBarra: this.isBarra
      })
      .finally(() => this.loadingSkeleton = false)
  },

  watch: {
    page (e) {
      this.paginacao(e)
    },

    status (val) {
      this.loadingPag = true
      this.page = 1
      const typeStatus = this.typeStatus ? 'Não' : 'Sim'
      this.getData({
        status: val, 
        dtStart: this.rangeDate.start, 
        dtEnd: this.rangeDate.end, 
        typeDate: this.typeDate, 
        typeStatus,
        isBarra: this.isBarra
      })
      .finally(() => this.loadingPag = false)
    },

    typeStatus (val) {
      this.loadingPag = true
      this.page = 1
      const typeStatus = val ? 'Não' : 'Sim'
      if (this.status) {
        this.getData({
          status: this.status,
          dtStart: this.rangeDate.start,
          dtEnd: this.rangeDate.end,
          typeDate: this.typeDate,
          typeStatus,
          isBarra: this.isBarra
        })
        .finally(() => this.loadingPag = false)
      }
    },
  },

  computed: {
    ...mapGetters('crm', ['listaItens', 'totalItens', 'listMemos']),
    variables: () => variables,

    itemsStatus: () => [
      { text: 'Whatsapp', value: 'whatsappactive' },
      { text: 'Celular Validado', value: 'validated_phone' },
      { text: 'Revender', value: 'acceptedreseller' },
    ],

    typeStatusFormat () {
      return this.typeStatus ? 'Não' : 'Sim' 
    }
  },

  methods: {
    ...mapActions('crm', ['getData', 'changeStatus', 'getMemosByEntity']),
    init () {},

    paginacao (pag) {
      this.loadingTable = true
      this.disabled = true
      this.setPage(pag)
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      this.getData({
        status: this.status,
        page: pag,
        size: this.$store.getters.ItensPaginacao,
        dtStart: this.rangeDate.start,
        dtEnd: this.rangeDate.end,
        typeDate: this.typeDate,
        typeStatus: this.typeStatusFormat,
        isBarra: this.isBarra
      })
      .finally(() => {
        this.loadingTable = false
        this.disabled = false
      })
    },

    filtrar (e) {
      this.rangeDate = {
        start: e.start,
        end: e.end
      }

      this.typeDate = e.selectFilter

      this.loadingTable = true
      this.getData({
          status: this.status,
          page: this.page,
          size: this.$store.getters.ItensPaginacao,
          dtStart: this.rangeDate.start,
          dtEnd: this.rangeDate.end,
          typeDate: this.typeDate,
          typeStatus: this.typeStatusFormat,
          isBarra: this.isBarra
        })
        .finally(() => this.loadingTable = false)
    },

    handlerCancelarBilhete (bilhete) {
      const msg = {
        cancel: `Tem certeza que deseja cancelar o bilhete:\n${bilhete.codigoId}`,
        notCancel: `Tem certeza que deseja reativar o bilhete:\n${bilhete.codigoId}`
      }

      const typeMessage = bilhete.canceled ? 'notCancel' : 'cancel'

      this.$swal({
        icon: 'warning',
        text: msg[typeMessage],
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      })
      .then((result) => {
        if (result.value) {
          const data = {
            cancellationStatus: bilhete.canceled ? 0 : 1,
            bilheteId: bilhete.bilhete_id,
            resultId: bilhete.resultId
          }

          this.loadingBtns = true
          this.cancelarBilhete(data)
            .then(() => {
              successSnackbar('Bilhete cancelado com sucesso!')
              this.loadingTable = true
              this.getData({ status: this.status, page: this.page, size: this.$store.getters.ItensPaginacao, dtStart: this.rangeDate.start, dtEnd: this.rangeDate.end })
                .finally(() => this.loadingTable = false)
            })
            .catch((error) => errorSnackbar(error.message))
            .finally(() => this.loadingBtns = false)
        }
      })
    },

    handlerBilhetePremiado (bilhete) {
      this.showModalPremiarBilhete = true
      this.bilhetePremiado = bilhete
    },

    handlerChangeStatus (entity, type) {
      const validatedPhone = entity.validated_phone === 'Sim' ? false : true
      const whatsapp = entity.whatsappactive === 'Sim' ? false : true
      const acceptedReseller = entity.acceptedreseller === 'Sim' ? false : true
      this.loadingBtns = true
      switch(type) {
        case 'telefone':
          this.changeStatus({ entityId: entity.entity_id, validatedPhone })
            .then(() => {
              this.refreshCRM()
            })
            .catch((error) => errorSnackbar(error.message))
            .finally(() => this.loadingBtns = false)
          break
        case 'whatsapp':
          this.changeStatus({ entityId: entity.entity_id, whatsapp })
            .then(() => {
              this.refreshCRM()
            })
            .catch((error) => errorSnackbar(error.message))
            .finally(() => this.loadingBtns = false)
          break
        case 'revendedor':
          this.changeStatus({ entityId: entity.entity_id, acceptedReseller })
          .then(() => {
              this.refreshCRM()
            })
            .catch((error) => errorSnackbar(error.message))
            .finally(() => this.loadingBtns = false)
          break
      }
      // this.showModalPremiarBilhete = true
      // this.bilhetePremiado = bilhete
    },

    isPhoneValided (entity) {
      return entity.validated_phone === 'Sim'
    },

    isWhatsappActivated (entity) {
      return entity.whatsappactive === 'Sim'
    },

    isAcceptedReseller (entity) {
      return entity.acceptedreseller === 'Sim'
    },

    isActiveBilhete (bilhete) {
      return !!bilhete.winner
    },

    closeModal () {
      this.showModal = false
    },

    refreshCRM () {
      this.showModal = false
      this.loadingTable = true
      this.getData({
        status: this.status || undefined,
        page: this.page,
        size: this.$store.getters.ItensPaginacao,
        dtStart: this.rangeDate.start,
        dtEnd: this.rangeDate.end,
        typeDate: this.typeDate,
        typeStatus: this.status ? this.typeStatus ? 'Não' : 'Sim'  : undefined,
        isBarra: this.isBarra
      })
      .finally(() => this.loadingTable = false)
    },

    searchByPhone (phone) {
      if (!phone.replace(/\D/g, '')) return

      this.loadingTable = true
      const phoneFormat = '55' + phone.replace(/\D/g, '')
      if (phoneFormat.length !== 13) return


      this.getData({ phone: phoneFormat })
        .finally(() => this.loadingTable = false)
    },

    getValorPremio (prize) {
      if (!prize) return '-'
      return currency(prize)
    },

    opemModalMemo (entity) {
      this.showModal = true
      this.entitySelected = entity
    },

    handlerGetMemosByEntity (data) {
      if (data.value) {
        this.loadingExpanded = true
        this.getMemosByEntity({ entity_id: data.item.entity_id })
          .finally(() => this.loadingExpanded = false)
      }
    },
  },

  validations () {
    return  {
      birthdayMonth: { required },
    }
  },

  beforeDestroy () {
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/components/tableWithVDeep';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;

    .v-data-table-header-mobile {
      display: none;
    }

    &.box-table-gecom {
      .v-data-table > .v-data-table__wrapper > table > tbody > tr > th {
        height: auto;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }

  .range-date::v-deep {
    .v-btn {
      height: 38px !important;
      width: 100%;
    }
  }

  .select-status::v-deep {
    .v-input__slot {
      margin-bottom: 0 !important;
    }
  }

  .bilhete-cancelado {
    background-color: $colorError;
    color: #FFF !important;
  }
  
  .bilhete-premiado {
    background-color: $colorSecondary;
    color: #FFF !important;
  }
  
  .telefone-validado {
    background-color: $colorSecondary;
    color: #FFF !important;
  }
  
  .whatsapp-ativado {
    background-color: $colorSecondary;
    color: #FFF !important;
  }
  
  .ser-revendedor {
    background-color: $colorSecondary;
    color: #FFF !important;
  }


  .box-table::v-deep {
    .v-input__slot {
      margin-bottom: 0 !important;
    }
  }


</style>

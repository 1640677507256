var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cadastro-usuario"},[_c('v-container',{attrs:{"fluid":""}},[_c('h1',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"titulo"},[_vm._v("CRM")]),_c('div',{staticClass:"box-table"},[_c('v-skeleton-loader',{attrs:{"loading":_vm.loadingSkeleton,"transition":'fade-transition',"height":"500","type":"table"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.listaItens,"width":"300px","height":"auto","flat":"","dense":"","hide-default-footer":"","expanded":_vm.expanded,"show-expand":"","item-key":'entity_id',"items-per-page":_vm.itemsPerPage,"loading":_vm.loadingTable},on:{"update:expanded":function($event){_vm.expanded=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"click:row":_vm.opemModalMemo,"item-expanded":_vm.handlerGetMemosByEntity},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('BrToolBar',{attrs:{"configFilter":{ 
                disableTextField: true 
              },"addlFilter":true},on:{"search":_vm.buscar},scopedSlots:_vm._u([{key:"btnSelect",fn:function(){return [_c('v-row',{staticClass:"justify-end align-center"},[_c('v-checkbox',{attrs:{"label":'Barra',"dense":"","hide-details":""},model:{value:(_vm.isBarra),callback:function ($$v) {_vm.isBarra=$$v},expression:"isBarra"}}),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##) #####-####'),expression:"'(##) #####-####'"}],staticClass:"mb-0",attrs:{"append-icon":"search","label":'Telefone',"single-line":"","hide-details":"","solo":"","dense":"","color":"primary"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchByPhone(_vm.search)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-checkbox',{attrs:{"label":'Não',"dense":"","hide-details":""},model:{value:(_vm.typeStatus),callback:function ($$v) {_vm.typeStatus=$$v},expression:"typeStatus"}}),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{staticClass:"select-status",attrs:{"items":_vm.itemsStatus,"label":"Status","dense":"","hide-details":"","solo":"","clearable":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('FiltroRangeDate',{staticClass:"mr-3 range-date",staticStyle:{"width":"100%"},attrs:{"value":_vm.rangeDate,"itemsTypesFilters":_vm.arrTiposFiltros,"labelBtn":'Selecionar Data',"actionLabels":{ apply: 'OK' },"disableBtnClear":false},on:{"input":_vm.filtrar}})],1)],1)]},proxy:true}])})]},proxy:true},{key:"item.cod",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("phone2")(item.cod))+" ")]}},{key:"item.cred_first",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("datas")(item.cred_first))+" ")]}},{key:"item.cred_last",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("datas")(item.cred_last))+" ")]}},{key:"item.credito_ativado",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.credito_ativado))+" ")]}},{key:"item.credito_gasto",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.credito_gasto))+" ")]}},{key:"item.dt_last_game",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("datas")(item.dt_last_game))+" ")]}},{key:"item.dt_validated_phone",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("datas")(item.dt_validated_phone))+" ")]}},{key:"item.lastlogin",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("datas")(item.lastlogin))+" ")]}},{key:"item.validated_phone",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",class:{ 'telefone-validado': _vm.isPhoneValided(item) },attrs:{"small":"","outlined":"","color":_vm.variables.colorError,"rounded":"","shaped":"","loading":_vm.loadingBtns},on:{"click":function($event){$event.stopPropagation();return _vm.handlerChangeStatus(item, 'telefone')}}},[_vm._v(" "+_vm._s(item.validated_phone === 'Sim' ? 'Validado' : 'Não Validado')+" ")])]}},{key:"item.whatsappactive",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",class:{ 'whatsapp-ativado': _vm.isWhatsappActivated(item) },attrs:{"small":"","outlined":"","color":_vm.variables.colorError,"rounded":"","shaped":"","loading":_vm.loadingBtns},on:{"click":function($event){$event.stopPropagation();return _vm.handlerChangeStatus(item, 'whatsapp')}}},[_vm._v(" "+_vm._s(item.whatsappactive === 'Sim' ? 'Sim' : 'Não')+" ")])]}},{key:"item.acceptedreseller",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",class:{ 'ser-revendedor': _vm.isAcceptedReseller(item) },attrs:{"small":"","outlined":"","color":_vm.variables.colorError,"rounded":"","shaped":"","loading":_vm.loadingBtns},on:{"click":function($event){$event.stopPropagation();return _vm.handlerChangeStatus(item, 'revendedor')}}},[_vm._v(" "+_vm._s(item.acceptedreseller === 'Sim' ? 'Sim' : 'Não')+" ")])]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"wrapper-footer"},[_c('div',{staticClass:"qtd-paginacao"},[_vm._v(" "+_vm._s(_vm.pageStart)+" - "+_vm._s(_vm.pageStop)+" de "+_vm._s(_vm.totalItens)+" ")]),(_vm.numberOfPages > 1)?_c('v-pagination',{staticClass:"box-table-paginacao",attrs:{"length":_vm.numberOfPages,"circle":"","disabled":_vm.disabled,"total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
              var headers = ref.headers;
              var item = ref.item;
return [_c('td',{staticClass:"table-expend ",attrs:{"colspan":headers.length}},[(_vm.loadingExpanded)?_c('v-col',{staticClass:"d-flex justify-center align-center",staticStyle:{"min-height":"150px","width":"100%"}},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1):_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headerMemos,"items":_vm.listMemos,"width":"300px","flat":"","hide-default-footer":"","items-per-page":_vm.listMemos.length},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.listMemos, "length", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.listMemos, "length", $event)}},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("datas")(item.created))+" ")]}}],null,true)})],1)]}}])})],1)],1)]),_c('ModalMemo',{attrs:{"activeModal":_vm.showModal,"entity":_vm.entitySelected},on:{"closeModal":_vm.closeModal,"refreshCRM":_vm.refreshCRM}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }